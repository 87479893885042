/**通用**/
.usercn{
  .back{
    display: none;
  }
}
/**登录**/
.loginTop{
   background-color: #fff;
  .loginTop-bar{
    height: 26px;
    background-color: #fafafa; line-height: 26px;
  }
  .loginTop-header{
    height: 92px;
    .loginTop-header-logo{

         margin-top: 20px; display: inline-block; height: 45px; margin-right: 20px;
    }
    .loginTop-header-txt{
       font-size: 24px; padding: 0 0 0 25px; border-left: 1px solid #e8e8e8; display: inline-block; vertical-align: middle;
    }
  }
} 
.loginBanner{
  height: 530px; background-image: url("/statics/images/bg/bg_login.jpg"); background-position: 50% 50%; background-size: cover;
  .loginBox{
    float:right;
    margin-top: 60px;
    margin-right: 30px;
    padding:  0 20px; 
    width: 295px;
    height: 389px;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 5px 5px 15px 2px rgba(0,0,0,0.3); //1.水平阴影  2.垂直阴影 3.模糊距离，改虚实 4.阴影尺寸
    h3{
      font-size: 16px;  text-align: center;
    }
    .loginBox-form{
      .loginBox-input{
             position: relative;
             height: 38px;
              .icons{
                position: absolute;
                left: 10px ;
                top:10px;
                display: inline-block;
                width: 20px; height: 20px;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                &.icon_user{
                  background-image: url("/statics/images/icon/icon_uuser.png");
                }
                &.icon_pass{
                  background-image: url("/statics/images/icon/icon_ulock.png");
                }
              }
           &.loginBox-input--code{

             .loginBox-input-code{
              float:left; width: 50%;

             }
             .loginBox-input-img{
               float:left;
               width: 130px;
               margin-left: 15px;
               height: 36px;
                        background-color: #ebebeb;
               border-radius: 2px;
               border: solid 1px rgba(210, 210, 210, 0.7);
               display: inline-block;
             }
           }
        input{
           text-indent: 25px;
        }
      }
      .loginBox-option{
        .layui-form-checkbox[lay-skin=primary]:hover i{
          border:1px solid #d1d1d1 !important;
        }
        .layui-form-checked{

          .layui-icon-ok{
            color: #e25b4b;
            background-color: #fff;
            border:1px solid #d1d1d1 !important;
          }
        }

      }
      .loginBox-submit{
      }
    }
  }
}  
/**注册**/
.regBox{
  margin-bottom: 155px;
  border-radius: 2px;   
  border: solid 1px #e8e8e8;
  padding-bottom: 60px;
  h3{
    font-size:18px; font-weight: bold; text-align: center; margin-top: 66px;padding-left: 130px;
  }
  .regBox-form{
    margin: 0 auto;
    width: 450px;
    .layui-form-label{
      width: 100px; color: #666;
       span{
         color: #ff3300; padding: 0 1px;
       }     
    }
    .layui-input-block{
      margin-left: 133px;
    }
    .regBox-input{  
      position: relative;
      height: 38px;
      &.regBox-input--check{
        .layui-form-checkbox{
          margin-top: 0;
        }
      }
      &.regBox-input--telmsg{
        .regBox-input-telmsg{
           width:154px; display: inline-block;
        }
        .regBox-input-btncode{
           display: inline-block; vertical-align: top;
        }

      }
      .icons{
        position: absolute;
        top: 10px ;
        right:10px;
        display: inline-block; 
        width: 20px; height: 20px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        &.icon_mb{
          background-image: url("/statics/images/icon/icon_mobile.png");
        }
        &.icon_lock{
          background-image: url("/statics/images/icon/icon_lock.png");
        }
      }
    }
  }    
}   

/**会员通用**/
.userLeft{
  width: 204px;
}   
.userCon{
   width: 972px;border: solid 1px #e8e8e8;
}
/**左侧菜单**/
.userMenu{
 h3{
   height: 53px;
   background-color: #01953f;
   display: block;
   letter-spacing: 1px;
   color: #ffffff;
   font-size: 18px;
   text-align: center;
   .icon_uindex{
     display: inline-block;
     width: 30px; height:53px;
     background-image:url("/statics/images/icon/icon_userh.png");
     background-repeat: no-repeat;
     background-position:center 50%;
     vertical-align: middle;
   }
   a{
     color: #fff;
     line-height: 53px;
   }
 }
  .userMenu-item{

    &.userMenu-item--open{

      h4{
        &>a{
          i{
            &::before{
              content: "\e61a";
            }
          }
        }
      }
      ul{
        display: block;
      }
    }
    &.userMenu-item-cur{
     h4{
       a{
         background-color: #dfeee5;
       }
     }
    }
     h4{
       display: block;
       height: 54px;
       border-left: 1px solid #e8e8e8;
       border-right: 1px solid #e8e8e8;
       border-bottom: 1px solid #e8e8e8;
       &>a{
         display: block;
         height: 54px;
         line-height: 54px;
         text-align: left;
         padding-left:40px;
         background-color: #fff;
         .icon{
           width: 20px;height: 20px; display: inline-block; background-repeat: no-repeat; background-position: 50% 50%; padding-right: 15px; vertical-align: middle;
           &.icon_base{
             background-image: url("/statics/images/icon/icon_idcard.png");
           }
           &.icon_safe{
             background-image: url("/statics/images/icon/icon_graybag.png");
           }
           &.icon_msg{
             background-image: url("/statics/images/icon/icon_msg.png");
           }
           &.icon_sup{
             background-image: url("/statics/images/icon/icon_graymg.png");
           }
           &.icon_pur{
             background-image: url("/statics/images/icon/icon_edit.png");
           }
         }
         i{
           width: 20px;height: 20px; display: inline-block; color: #333; float: right; margin-right: 25px;
           &::before{
             content: "\e602";
           }
         }
       }
     }
    ul{
      display: none;
      li{

        height: 46px; border-left: 1px solid #e8e8e8; line-height: 46px; text-align: center;
        border-right: 1px solid #e8e8e8;

          &.userMenu-item-itemcur{
            background-color: #dfeee5;
          }

      }
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
// 通用页脚
.uFooter{
  height: 69px;
  margin-top: 20px;
  background-color: #ffffff;
  line-height: 69px;
  text-align: center;
}

/**会员首页**/
.uindexInfo{
  width: 927px;
  height: 157px;
  background-color: #ffffff;
  border: solid 1px #e6e9ed;
  margin: 0 auto;
  .uindexInfo-base{
      width: 340px;
    position: relative;
     padding-left: 100px;
    .uindexInfo-base-avator{
      position: absolute;
      left: 0;
      top: 0;
      width: 82px;
      height: 83px;
      border-radius: 50%;
      background-color: #ededed;
    }
    h4{
      font-size: 14px; white-space: nowrap;
    }
  }
  .uindexInfo-option{
    width: 430px;
    .uindexInfo-option-btn{
      width: 187px; display: inline-block;
      height: 65px;
      background-color: #65bb4e;
      line-height: 65px; font-size: 16px; font-weight: bold;
      i{
        display: inline-block;
       width: 38px;
        height: 38px;
        background-repeat: no-repeat; background-position: 50% 50%;
        background-image: url("/statics/images/icon/icon_bigmg.png");
        vertical-align: middle;
        margin-right: 10px;
      }
      &.uindexInfo-option-btn--pure{
        background-color: #fd9a3f;
        i{
          background-image: url("/statics/images/icon/icon_bigsend.png");
        }
      }
    }

  }
}
.uindex-tab{
  position: relative;

  background-image: url("/statics/images/bg/bg_hline.jpg");
  background-repeat: repeat-x;
  background-position: 0 bottom;
  height: 40px; font-size: 0;
  li{
    height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
    &.cur{
      border-bottom: 4px solid #01953f; height: 37px;
      a{
        color: #333;
        font-weight: bold;
      }
    }


  }
  .moreLink{
    position: absolute;
    right: 10px;
    display: inline-block;
  }
}
/**基本信息**/
.baseInfo{
  margin: 0 150px 0 30px;
  .baseInfo-item{
    .layui-form-label{
      width:150px;
    }
    .layui-input-block{
      margin-left: 183px;
    }
    &.baseInfo-item--area{
      .layui-form-select{
        width: 130px;
        display: inline-block;
      }
    }
  }
}
/**修改密码**/
.upassword{
  margin: 0 450px 0 30px;
  .layui-form-label{
    width:150px;
  }
  .layui-input-block{
    margin-left: 183px;
  }
}
/**绑定手机**/
.changeTel{
  margin: 0 400px 0 30px;
  .changeTel-item{
    .layui-form-label{
      width:180px;
    }
    .layui-input-block{
      margin-left:210px;
      .changeTel-item-telmsg{
        display: inline-block; width:130px;  vertical-align: top;
      }
    }

  }

}
/**供货列表**/
.usupply-sendbtn{
  width: 124px;
  height: 34px;
  background-color: #fe9941;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  line-height: 34px;
  text-align: center;

}
.usupplyList{
  .usupplyList-pic{
     img{
       width: 70px;
       height: 70px;
       display: block;
     }
    h3{
      display: block;
      width: 120px;
      margin-left: 10px;
    }
  }
}
/**求购列表**/
.upureList-sendbtn{
  width: 124px;
  height: 34px;
  background-color: #fe9941;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  line-height: 34px;
  text-align: center;

}
.upureList{
  .upureList-pic{
    img{
      width: 70px;
      height: 70px;
      display: block;
    }
    h3{
      display: block;
      width: 120px;
      margin-left: 10px;
    }
  }
  .play{
     .layui-btn{
       display: block;
       height: 30px;
       line-height: 30px;
       &.layui-btn-primary{
         color: #01953f;
       }
       &.layui-btn--sec{
         background-color: #fff; color: #fe9941;
       }
       &+.layui-btn{
         margin-left: 0;
       }
     }
  }
}
/**供货发布**/
.upurchaseForm{
  .upurchaseForm-item{
    .layui-input--wxl{
      width: 150px; display: inline-block; vertical-align: top;
    }
    .layui-form-select{
       width: 150px; display: inline-block;
    }
  }
}
/**求购发布**/
.purchaseForm{
  .purchaseForm-item{
    .layui-input--wxl{
      width: 150px; display: inline-block; vertical-align: top;
    }
    .layui-form-select{
      width: 150px; display: inline-block;
    }
  }
}
/**消息详情**/
.msgShow-item{
  border-bottom: solid 1px #e8e8e8;
  .msgShow-item-block{
    padding: 9px 15px;
  }
  .msgShow-item-avator{
     width: 30px; height: 30px; border-radius: 50%; display: inline-block;border:1px solid #d1d1d1; vertical-align: middle;
  }

}
/** getpassword**/
.getPass{

}

  .layui-input-inline{
      white-space: nowrap;
      .layui-input{
          display: inline-block !important;
      }
      img{display: inline-block !important;}
  }
