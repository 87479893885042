/**首页**/
//轮转
.indexLeft{
  width: 808px;
}
.indexRight{
  width: 368px;

}

.index{}
.indexSwiper{
  height: 382px;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  &.swiper-container{
    margin:0 auto;
    position:relative;
    overflow:hidden;
    direction:ltr;
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
    /* Fix of Webkit flickering */
    z-index:1;
    .swiper-wrapper{
      position:relative;
      width:100%;
      -webkit-transition-property:-webkit-transform, left, top;
      -webkit-transition-duration:0s;
      -webkit-transform:translate3d(0px,0,0);
      -webkit-transition-timing-function:ease;

      -moz-transition-property:-moz-transform, left, top;
      -moz-transition-duration:0s;
      -moz-transform:translate3d(0px,0,0);
      -moz-transition-timing-function:ease;

      -o-transition-property:-o-transform, left, top;
      -o-transition-duration:0s;
      -o-transform:translate3d(0px,0,0);
      -o-transition-timing-function:ease;
      -o-transform:translate(0px,0px);

      -ms-transition-property:-ms-transform, left, top;
      -ms-transition-duration:0s;
      -ms-transform:translate3d(0px,0,0);
      -ms-transition-timing-function:ease;

      transition-property:transform, left, top;
      transition-duration:0s;
      transform:translate3d(0px,0,0);
      transition-timing-function:ease;

      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      a{
        width: 100%;
        height: 100%;
        display: block;
        float: left;
        .indexSwiper-linkcon{
           display: inline-block;
          width: 100%; height: 100%;
          position: relative;
          .indexSwiper-linkcon-txt{
             position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 50px;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#FF000000',GradientType=0 ); /* IE6-9 */
            background:none; /* 一些不支持背景渐变的浏览器 */
            background:-moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
            background:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 0, 0.0)), to(rgba(0, 0, 0, 0.5)));

            span{
               color: #fff; font-size: 16px; line-height: 48px; letter-spacing: 2px;
               padding: 20px 0 20px 20px; white-space: nowrap;
            }
          }

        }

      }
    }
    .indexSwiper-page{
      position: absolute;
      right:10px;
      width:auto ;
      margin: 0 auto;
      height:30px;
      bottom:10px;
      text-align: center;
      span{
        width: 6px; height: 6px; display: inline-block; *display:inline;*zoom:1; background:#fff; border-radius: 50%; opacity: 0.5;
        margin: 0 5px;
        &.swiper-active-switch{
          opacity: 1;
        }
      }
    }

  }
}
//右侧置顶
.indexTop{
  .indexTop-item{
     width: 100%;
    height: 179px;
    border-radius: 15px;
    a{
       display:block;
       width: 100%;
      height: 100%;
      position: relative;
      b{
         position: absolute;
        top:40px;
         background-image: url("/statics/images/icon/icon_top.png");
        background-repeat: no-repeat;
        width:58px; height: 28px;
      }
       span{
          position: absolute;bottom: 0; left: 0;right: 0;
         display: block; line-height: 40px; padding: 0 10px;font-size: 16px;letter-spacing: 2px;color: #ffffff;
       }
    }
    &--sec{
      margin-top: 24px;
    }
  }
  .indexTop-img{
    border-radius:15px;
  }
}

//新闻动态
.indexNews{
  .indexNews-tab{
     position: relative;

      background-image: url("/statics/images/bg/bg_hline.jpg");
      background-repeat: repeat-x;
      background-position: 0 bottom;
      height: 40px; font-size: 0;
    li{
       height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
       &.cur{
         border-bottom: 4px solid #01953f; height: 37px;
         a{
           color: #333;
           font-weight: bold;
         }
       }


    }
    .moreLink{
       position: absolute;
      right: 10px;
      display: inline-block;
    }

  }
  .indexNews-con{
     display: none;
    ul{
      padding-left: 20px; padding-right: 20px;
      li{
        position: relative; height: 140px; padding-left: 228px; margin-top: 14px; border-bottom: 1px dashed #e8e8e8;
        &:last-child{
          border-bottom: none;
        }
        img{
           display:block; width: 208px; height: 129px; border:1px solid #dddddd; position: absolute;left:0; top: 0;
        }
        h3{
          letter-spacing: 2px;color: #333333; font-size: 16px; font-weight:bold; white-space: nowrap;
        }
        p{
          line-height: 25px; margin-top: 21px;
          letter-spacing: 1px;
          color: #787878;
        }
        em{
           font-style: normal;
           position: absolute;
           left: 228px;
           bottom:10px;line-height: 28px;
          letter-spacing: 1px;
          color: #787878;
        }
      }
    }
  }

}
//公告
.indexNotice{
   height: 519px;
  .indexNotice-tab{
    position: relative;

    background-image: url("/statics/images/bg/bg_hline.jpg");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    height: 40px; font-size: 0;
    li{
      height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
      &.cur{
        border-bottom: 4px solid #01953f; height: 37px;
        a{
          color: #333;
          font-weight: bold;
        }
      }


    }
    .moreLink{
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }
  .indexNotice-con{
    height: 464px;
    display: none;
    ul{
       padding-left: 20px; padding-right: 20px; padding-top: 20px;
      li{
        height: 87px;
        position: relative;
        background-image: url("/statics/images/bg/bg_leftline.jpg");
        background-position:3px 5px;
        background-repeat: repeat-y;
        a{
          background-image: url("/statics/images/icon/dot.png");
          background-position: 0 10px;
          background-repeat: no-repeat; display: block;
           padding-left: 15px;line-height: 25px; font-weight: bold;
          letter-spacing: 1px;
        }
        em{
          position: absolute;
          bottom: 10px;
          letter-spacing: 1px;
          display: block;
          color: #787878; font-style: normal;
          font-size: 14px;
          padding-left:15px;
        }
      }
    }

  }
}

//招商投资
.indexBiz{
  .indexBiz-con{
    padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 10px;
    .indexBiz-con-tab{
      li{
        height: 42px; background: #f6f6f6;
        border-radius: 5px;
        display: inline-block;
        line-height: 42px; margin-right: 8px; font-size: 16px; padding: 0 20px; cursor: pointer;
        &.cur{
          color: #fff; background: #01953f;
        }
      }
    }
    .indexBiz-con-con{
       display: none;
         height: 230px;
      h3{
        font-size: 26px; line-height: 70px; margin-top: 46px;
        a{
          color:#01953f ;
        }
      }
      p{
         font-size: 14px;
        line-height: 30px;
        letter-spacing: 1px;
      }

    }
    .indexBiz-con-swiper{
      width: 100%;
      height: 190px;
      position: relative;
      z-index: 1;
      &.swiper-container{
        margin:0 auto;
        position:relative;
        overflow:hidden;
        direction:ltr;
        -webkit-backface-visibility:hidden;
        -moz-backface-visibility:hidden;
        -ms-backface-visibility:hidden;
        -o-backface-visibility:hidden;
        backface-visibility:hidden;
        /* Fix of Webkit flickering */
        z-index:1;
        .swiper-wrapper{
          position:relative;
          width:100%;
          -webkit-transition-property:-webkit-transform, left, top;
          -webkit-transition-duration:0s;
          -webkit-transform:translate3d(0px,0,0);
          -webkit-transition-timing-function:ease;

          -moz-transition-property:-moz-transform, left, top;
          -moz-transition-duration:0s;
          -moz-transform:translate3d(0px,0,0);
          -moz-transition-timing-function:ease;

          -o-transition-property:-o-transform, left, top;
          -o-transition-duration:0s;
          -o-transform:translate3d(0px,0,0);
          -o-transition-timing-function:ease;
          -o-transform:translate(0px,0px);

          -ms-transition-property:-ms-transform, left, top;
          -ms-transition-duration:0s;
          -ms-transform:translate3d(0px,0,0);
          -ms-transition-timing-function:ease;

          transition-property:transform, left, top;
          transition-duration:0s;
          transform:translate3d(0px,0,0);
          transition-timing-function:ease;

          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
          li{

            display: block;
            float: left;
            overflow: hidden;
            img{
              display: block;
              width: 236px;
              height: 137px;
              margin:  0 auto;
            }


          }
        }
        .indexSwiper-page{
          position: absolute;
          right:10px;
          width:auto ;
          margin: 0 auto;
          height:30px;
          bottom:10px;
          text-align: center;
          span{
            width: 6px; height: 6px; display: inline-block; *display:inline;*zoom:1; background:#fff; border-radius: 50%; opacity: 0.5;
            margin: 0 5px;
            &.swiper-active-switch{
              opacity: 1;
            }
          }
        }

      }
      .indexBiz-con-swiperpage{
         position: absolute;
        bottom: 10px;
         width: 100%;
        text-align: center;
        span{
          margin: 0 3px;
          width: 9px;
          height: 9px;
          border: solid 1px #2faf5c;
          border-radius: 50%;
          border-radius: 50%;
          display: inline-block;
          &.swiper-active-switch{
            background: #2faf5c;
          }
        }
      }
    }

  }
}

//菌菇美食
.indexFood{
  height:583px;
  .indexFood-top{
    padding: 10px 20px 0 20px;
    img{
      display: block;
      width: 328px;
       height: 162px;
    }
    span{
      display: block;
      white-space: nowrap;
      line-height: 36px;
    }
    em{
      display: block; letter-spacing: 1px;
      font-size: 14px;
      color: #787878;
      margin-top: 30px; font-style: normal;
    }
  }
  .indexFood-list{
    margin: 10px 20px 0 20px;
    border-top:1px dashed #dadada;
    padding: 10px 0;
    ul{
      li{
        background-image: url("/statics/images/bg/bg_dot.png");
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 15px;
        font-size: 14px;
        color: #333;
        line-height: 30px; letter-spacing: 1px;
        white-space: nowrap;
      }
    }
  }
}

//供货求购
.suppur{
  height: 465px;
  .suppur-tab{
    position: relative;

    background-image: url("/statics/images/bg/bg_hline.jpg");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    height: 40px; font-size: 0;
    li{
      height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
      &.cur{
        border-bottom: 4px solid #01953f; height: 37px;
        a{
          color: #333;
          font-weight: bold;
        }
      }


    }
    .moreLink{
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }
  .suppur-con{
    padding: 0 0 0 0;
     display: none;
    margin: 0 20px;
    height: 400px;
    &.pureList-list{
      ul{
        li{
           height:85px; position: relative; margin-top: 15px;
           padding-right: 150px;
           border-bottom: 1px dashed #e8e8e8;
          h3{
             font-size: 16px; font-weight: bold;
          }
          p{
            line-height: 25px;
            margin-top: 5px;
            color: #787878; display: block;
            height:26px; overflow: hidden; text-overflow: ellipsis;
          }
          .pureList-list-tips{
            margin-top: 10px;
            .pureList-list-zone{
              display: inline-block; background-image: url('/statics/images/icon/icon_ps.png');
              background-repeat: no-repeat;
              background-position: 0 center;
              padding-left: 18px;
              margin-right: 30px;
            }
            .pureList-list-ptime{
              display: inline-block;
              color: #787878;
            }
          }
          .pureList-list-btn{
              position: absolute;
              right: 20px;
              top:30px;
              line-height: 24px;
              border:1px solid #e8e8e8;
              padding:2px 10px;



          }

        }
      }
    }
  }
}

//供货列表
.supTable{
  tr{
    th{
      border-bottom: 1px solid #f0f0f0;
      font-size: 14px;
      background-color: #f3f4f4;
      height: 30px;
    }
    td{
      height: 45px;
      border-bottom: 1px dashed #f0f0f0; white-space: nowrap;
      .supply_btn{
        display: inline-block;
        line-height: 24px;
        border:1px solid #e8e8e8;
        padding:2px 10px;
      }
    }
  }
}
//市场行情
.indexMarket{
  height: 465px;
  .indexMarket-canvas{
    height: 250px;
  }
  .indexMarket-ul{
    margin-top: 15px;
    li{
      width: 59px;
      height: 32px;
      font-size: 14px;
      border-radius: 5px;
      background-color: #f6f6f6;
      display: inline-block;
      text-align: center;
      line-height: 32px;
      &.cur{
        background-color: #01953f;
        color: #fff;
        & a:link{
          color: #ffffff;
      }
        & a:visited{
            color: #ffffff;
        }
        & a:hover{
            color:#ffffff;
        }
        & a:active{
          color:#ffffff;
      }
      }

    }
  }
  .indexMarket-option{
    .layui-input-inline{
      width: 130px;
      .layui-form-select {
        .layui-input{
          border-color: #01953f;
          
        }
        .layui-edge{
          border-top-color: #01953f;
        }

      }
    }
  }
}
//首页-广告
.indexbanner{
  height: 92px;
  background-color: #e9e9e9;
  img{
    display: block;
    height: 92px;
    width: 1200px;
  }
}

//技术
 .indexTech{
   .indexTech-tab{
     position: relative;

     background-image: url("/statics/images/bg/bg_hline.jpg");
     background-repeat: repeat-x;
     background-position: 0 bottom;
     height: 40px; font-size: 0;
     li{
       height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
       &.cur{
         border-bottom: 4px solid #01953f; height: 37px;
         a{
           color: #333;
           font-weight: bold;
         }
       }

     }
     .moreLink{
       position: absolute;
       right: 10px;
       display: inline-block;
     }
   }
   .indexTech-con{
     margin: 0 20px;
     height: 373px;
     display: none;
     .indexTech-con-posi{
       height: 142px;
       position: relative;
       padding-left: 223px;
       img{
         width: 209px; height: 142px;
         display: block;
         position: absolute;
         left: 0;
         top:0;
       }
       .indexTech-con-pcon{
         h3{
           font-size: 16px;letter-spacing: 1px; font-weight: bold;
         }
         p{
           font-size: 14px; line-height: 30px; margin-top: 25px;
           a{
             color: #2faf5c;
           }
         }
       }
     }
     .indexTech-con-list{
       padding-top: 10px; border-top: 1px solid #e1e1e1; margin-top: 15px;
       li{
         background-image: url(/statics/images/bg/bg_dot.png);
         background-repeat: no-repeat;
         background-position: center left;
         padding-left: 15px;
         overflow: hidden;
         height: 30px;
         line-height: 30px;
         a{
           display: inline-block; float: left;
         }
         span{
           float: right; color: #878787;
         }
       }
     }
   }
 }
 //  政策
.indexPolicy{
  .indexPolicy-tab{
    position: relative;

    background-image: url("/statics/images/bg/bg_hline.jpg");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    height: 40px; font-size: 0;
    li{
      height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
      &.cur{
        border-bottom: 4px solid #01953f; height: 37px;
        a{
          color: #333;
          font-weight: bold;
        }
      }


    }
    .moreLink{
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }
  .indexPolicy-con{
    height: 373px;
    margin: 0 20px;
    padding-top:15px;
    ul{
      li{
        background-image: url(/statics/images/bg/bg_dot.png);
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 15px;
        overflow: hidden;
        line-height: 40px;
        white-space: nowrap;
      }
    }
  }
}
// 图文新闻
.indexPic{
  ul{
     font-size: 0;
    margin-left: 20px;
    margin-right:-20px;
    padding-bottom: 20px;
    li{
      width: 272px; height: 169px;  display: inline-block; margin-right: 24px;position: relative; font-size: 14px; margin-top: 20px;
      img{
        display: block; width: 272px; height: 169px;
      }
      .indexPic-item-txt{
        display:block;
        position: absolute;
        bottom: 0;
        left:0;
        right:0;
        height: 58px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #ffffff;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#FF000000',GradientType=0 ); /* IE6-9 */
        background:none; /* 一些不支持背景渐变的浏览器 */
        background:-moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
        background:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 0, 0.0)), to(rgba(0, 0, 0, 0.5)));
        line-height: 80px;
         padding: 0 10px;

      }
    }
  }
}
//企业列表

.company-list{
  font-size: 0;
  margin: 0 -23px 0 20px;
  padding-top: 20px; padding-bottom: 20px;
  li.company-list-item{
    margin-bottom: 20px;
    vertical-align: top;
    font-size: 14px;
    display: inline-block;
    width: 270px;
    height: 284px;
    background-color: #ffffff;
    border: solid 1px #e8e8e8;
    margin-right: 23px;
    img{
      display: block; width: 270px; height: 98px; margin: 0 auto;
    }
    h3{
      display: block;
      padding: 10px 10px 0  10px;
      a{
        font-size: 14px;letter-spacing: 1px; font-weight: bold; white-space: nowrap;
        text-align: center;
      }
    }
    p{
      text-indent: 30px;
      display: block;
      height: 38px;
      margin: 15px 10px 0 10px;
      background-image:url(/statics/images/icon/icon_graybag.png);
      background-repeat: no-repeat;
      background-position: 0 3px;
      color: #787878;
      line-height: 22px;
    }
    em{
      margin: 15px 10px 0 10px;
      background-image:url(/statics/images/icon/icon_graytel.png);
      background-repeat: no-repeat;
      background-position: 0 3px;
      color: #787878;
      line-height: 25px;
      text-indent: 30px;
      font-style: normal;
      display: block;
    }
    .company-list-more{
      margin: 15px 10px 0 10px;
        border-top: 1px solid #e8e8e8;
      text-align: right;
      background-image:url(/statics/images/icon/icon_grayar.png);
      background-repeat: no-repeat;
      background-position: right 15px;
      padding-right: 30px;
      padding-top: 10px;
    }
  }
}
//首页友情链接
.frlink{
  width: 100%;
  background: #fff;
  .frlink-con{
    ul{
      font-size: 0;
      margin: 0 -30px 0 20px;
      padding: 0px 0 20px 0;
      li{
        font-size: 14px;
        display: inline-block;
        margin-top: 20px;
        width: 200px;
        height: 96px;
        background-color: #ffffff;
        border: solid 1px #e8e8e8;
        margin-right: 30px;
        img{
          display: block;
          width: 198px;
          height: 96px;
        }
      }
    }
  }
}

/** 新闻列表**/
.newsList--list{
  width: 100%;
}
.newsList{
  .newsList-nav{
    position: relative;

    background-image: url("/statics/images/bg/bg_hline.jpg");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    height: 40px; font-size: 0;
    li{
      height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
      &.cur{
        border-bottom: 4px solid #01953f; height: 37px;
        a{
          color: #333;
          font-weight: bold;
        }
      }
    }

  }
  .newsList-list{

      padding-left: 20px; padding-right: 20px;
      li{
        position: relative; height: 140px; padding-left: 228px; margin-top: 14px; border-bottom: 1px dashed #e8e8e8;
        &:last-child{
          border-bottom: none;
        }
        img{
          display:block; width: 208px; height: 129px; border:1px solid #dddddd; position: absolute;left:0; top: 0;
        }
        h3{
          letter-spacing: 2px;color: #333333; font-size: 16px; font-weight:bold; white-space: nowrap;
        }
        p{
          line-height: 25px; margin-top: 21px;
          letter-spacing: 1px;
          color: #787878;
        }
        em{
          font-style: normal;
          position: absolute;
          left: 228px;
          bottom:10px;line-height: 28px;
          letter-spacing: 1px;
          color: #787878;
        }
      }

  }
}
//热门新闻
.newsHot{
  ul{
    padding: 10px 20px;
    li{
      white-space: nowrap; height: 30px; overflow: hidden;
      line-height: 30px;
      span{
        width: 16px;
        height: 16px;
        background-color: #e8e8e8;
        color: #787878;
        text-align: center;
        line-height: 16px;
        border-radius: 3px;
        display: inline-block;
        &.newHot-nm-1{
          background-color: #ff3434;
          color: #fff;
        }
        &.newHot-nm-2{
          background-color: #ff9940;
          color: #fff;
        }
        &.newHot-nm-3{
          background-color: #65bb4e;
          color: #fff;
        }
      }
      a{
         padding-left: 10px;
        font-size: 14px;
        letter-spacing: 1px;
        white-space: nowrap;
      }
    }

  }
}
// 图片推荐
.newsSidepic{
  ul{
    margin: 0 -25px 0 20px;
    font-size: 0;
    padding: 10px 0 18px;
    li{
      display: inline-block;
      font-size: 14px;  
      width: 147px;
      height: 140px;
      margin-right: 25px;
      margin-top: 10px;
      img{
         width: 151px; height: 86px; display: block;  
      }
      .newsSidepic-txt{
        height: 38px; margin-top: 8px; display: block;
        line-height: 20px; overflow: hidden; text-overflow: ellipsis;
      }
    }
  }
}

/**新闻详情**/
.newsCon{
  .newsCon-con{
    h1{
      margin: 0 70px;
    }
    .newsCon-con-info{
       margin-top: 30px;
      letter-spacing: 1px;
      color: #787878;
      border-bottom: 1px solid #e8e8e8;
      margin: 0 70px;
      line-height: 60px;
    }
    .newCon-con-content{
      padding: 20px 0 ;
      margin: 10px 70px 5px 70px;
      // *{
      //   margin-bottom: 10px;
      // }

    }
    .newCon-con-pn{
      margin: 0 70px;

      .newCon-con-pre{
        width: 319px;
        a{  display: block ; height: 43px; padding: 10px 20px 10px 35px;   background-color: #f6f6f6;
          background-image:url(/statics/images/icon/icon_al.png);
          background-repeat: no-repeat;
          background-position: 10px 15px;
          &:hover{
            background-image:url(/statics/images/icon/icon_al_hover.png);
            background-color: #01953f;
            color: #fff;
          }
        }
       }
      .newCon-con-nxt{
        width: 319px;
        a{   display: block;  height: 43px; padding: 10px 35px 10px 20px;    background-color: #f6f6f6;
          background-image:url(/statics/images/icon/icon_ar.png);
          background-repeat: no-repeat;
          background-position: 290px 15px;
          &:hover{
            background-image:url(/statics/images/icon/icon_ar_hover.png);
            background-color: #01953f;
            color: #fff;
          }
        }
      }
    }
  }
  .newsCon-about{
    ul{
      font-size: 0;
      margin: 0 -23px 0 20px;
      padding: 0 0 10px 0;
      li{
         width: 239px; height: 180px; margin-right:23px; font-size: 14px; display: inline-block; margin-top:15px;
        img{
          width: 239px; height: 133px; display: block;
        }
        .newsCon-about-txt{
          display: block;
          height: 38px; width: 100%; font-size: 14px; line-height: 20px; overflow: hidden; text-overflow: ellipsis;
        }
      }
    }
  }
}
/**专家**/
//分类
.expertType{

  background-color: #ffffff;
  padding: 10px;
  dl{
    font-size: 0;
    dt{
      display: inline-block; font-weight: bold; margin-right: 10px; height:30px; font-size: 14px; line-height: 30px;
    }
    dd{
      display: inline-block; margin-right: 15px;height: 30px;font-size: 14px; line-height: 30px;
      &.cur{
        color:#01953f;
        height: 26px;
        line-height: 26px;
        color: #01953f;
        font-weight: bold;
        border-bottom: 4px solid #01953f;
        a{
          color: #01953f;
        }
      }
    }
  }
}
// 专家列表
.expertList{
  min-height: 440px;
  ul{
    font-size: 0;
    margin: 0  -24px 0 19px;
    padding-bottom: 20px;
    &>li{
      vertical-align: top;
      margin-right: 24px;
      font-size: 14px;
       width: 370px;
       height: 193px;
       border:1px solid #e8e8e8;
       display: inline-block;
       margin-top: 24px;
      position: relative;

      .expertList-info{

        padding-left: 125px;
        img{
          display: block; width: 94px; height: 108px;  position: absolute; left:20px; top: 20px;
        }
        h3{
          padding-top:20px; white-space: nowrap;
          b{
            font-size: 18px; letter-spacing: 2px; margin-right:10px;
          }
          span{
            color: #787878; letter-spacing: 1px;
          }
        }
        p{
          display: block;
           margin-top: 20px; font-size: 12px;
          line-height: 25px;
          height: 61px; text-overflow: ellipsis;
          padding-right: 10px;
        }
      }
      .expertList-tag{
         margin: 10px auto 0  auto; position: absolute;
         overflow: hidden;
        //background-color: #fff;
        //border:1px solid #e8e8e8;
        left: -1px;
        font-size: 0;
        z-index: 99;
        width: 350px;
        padding-left: 20px;
        white-space: nowrap;
        span{
          font-size: 12px; color:#01953f; 	background-color: #d9efe2; height: 26px; padding: 0 10px;display: inline-block; line-height: 26px; margin-top: 15px; margin-right: 10px;
          border-radius: 13px;

        }
      }

      &.expertList-item--hover{
        .expertList-tag{
          background-color: #fff;
          border:1px solid #e8e8e8;
          border-top: none;
          white-space: normal;
          padding-bottom: 10px;
        }

      }
    }
  }
}
//专家详情
.expertInfo{
  .expertInfo-intro{
    position: relative;
    margin: 20px 20px 0 20px;
    padding-left: 165px;
    .experInfo-intro-avator{
      position: absolute;
      left: 0;
      display: block; width: 139px;
      height: 159px;
      background-color: #f7f7f7;
    }
    h3{
      padding-top:0px; white-space: nowrap;
      b{
        font-size: 18px; letter-spacing: 2px; margin-right:10px;
      }
      span{
        color: #787878; letter-spacing: 1px;
      }
    }
    .experInfo-intro-info{
      font-size: 0;
      li{
        width: 50%;  font-size: 14px; display: inline-block; height: 30px;vertical-align: top; margin-top: 15px;
      }
    }
    .expertInfo-tag{
      margin-top: 15px;
      span{
         padding-right: 10px;
      }
    }
  }
  .expertInfo-con{
     margin: 20px;
  }
  .expertInfo-result{
    margin: 20px;
  }
}
//相关专家
.expertAbout{
  ul{
    margin: 10px 20px 0 20px;
    li{
       height: 118px;
       border-bottom: 1px solid #e8e8e8;
       position: relative;
       padding-left: 104px;
       margin-top: 10px;
      &:last-child{
        border-bottom: none;
      }
       img{
         position: absolute;
         left: 0;
         top:0;
         width: 94px;
         height: 108px;
       }
      h3{
        padding-top:0px; white-space: nowrap;
        b{
          font-size: 16px; letter-spacing: 2px; margin-right:10px;
        }
        span{
          color: #787878; letter-spacing: 1px; font-size: 14px;
        }
      }
      p{
        display: block;
        height: 39px;
        font-size: 14px;
        line-height: 20px;
        margin-top:41px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

}
// 企业列表
.cpFilter{
   height: 81px;
  .cpFilter-search{
    position: relative;
    width: 497px;
    height: 42px;
    margin: 20px  auto 0 auto;
    border-radius: 5px;
    input{
      width: 497px;
      height: 42px;
      background-color: #f6f6f6;
      border-radius: 5px;
      border: solid 1px rgba(210, 210, 210, 0.8);
      text-indent: 10px;
      position: absolute;
      z-index: 1;
    }
    .cpFilter-search-btn{
      background-image:url(/statics/images/icon/icon_search.png);
      background-repeat: no-repeat;
      background-color: #01953f;
      background-position: 10px 50%;
      display: inline-block;
      position: absolute;
      letter-spacing: 2px;
      font-size: 16px;
      z-index:10 ;
      width: 83px; height: 44px;
      text-indent: 20px;
      color: #fff;
      right: -1px;
      top:0px;
      border-radius: 0 5px 5px 0;
    }
  }
}
//企业详情
.banner{
  height: 276px;
  margin:0 auto;
  background:url(/statics/images/bg/bg_banner.png) no-repeat;
  p{
    font-size: 30px;
    color:#fff;
    text-align: center;
    padding: 124px 0 114px;
  }
}
.companyBase{
  height: 174px;
  min-height: 174px;
  position: relative;
  padding-left: 232px; 
  img{
    width: 191px;
    height: 134px;
    background-color: #ffffff;
    border: solid 1px #e8e8e8;
    display: block;
    position: absolute;
    left:20px;
    top:20px;
  }
  h1{
    font-size: 18px;
    margin-top: 35px;
  }
  p{
    color: #787878; margin-top: 26px;
    line-height: 25px;
  }

}
.companyInfo{
   width: 893px;
   .introduce{
    min-height: 332px;
    p{
      text-indent: 2em;
      line-height: 25px;
      width: 846px;
      color:#333333;
    }
   }
   .supply{
    height: 564px;
     .more{
       font-size: 14px;
       float: right;
       line-height: 30px;
       img{
         margin-left: 10px;
        }
     }
     ul{
      height: 390px;
      li{
        width: 196px;
        height: 239px;
        border: solid 1px #e0e0e0;
        float: left;
        margin-left: 20px;
        img{
          margin-left: 1px;
        }
        p{
          width: 170px;
          line-height: 22px;
          font-size: 14px;
          margin-top: 2px;
          color:#333333;
          margin-left: 15px;
        }
        .money{
          margin-top: 10px;
          color: #fd641f;
          span{
            font-size: 18px;
          }
        }
       }
     }

   }
   .askBuy{
     height:815px;
    ul{
      height:660px;
      li{
        width: 851px;
        height: 125px;
        border-top:1px dashed #e8e8e8;
      .txt{
        width: 106px;
        height: 34px;
        background-color: #01953f;
        border-radius: 3px;
        color: #fff;
        font-weight: normal;
        text-align: center;
        line-height: 34px;
        margin-top: 50px;
      }
      & a:hover .txt{
        background-color:#2faf5c;
      }
        p{
          margin-top: 15px;
          font-weight: bold;
          font-size: 14px;
        }
        .companyLink-item--line{
          font-size: 14px;
          margin-top: 30px;
          img{
            display: inline-block;
          }
          .local{
            font-weight: normal;
            font-size: 14px;
            display: inline-block;
            color:#333;
            
          }  
         }
       }
    }


   }

  .companyInfo-con{
    margin: 10px 20px;
  }
  .companyInfo-tab{
    margin:  0 20px;
    li{
      margin-right: 15px;
      display: inline-block;
      margin-top: 15px;
      &.cur{
        a{
          background-color: #01953f;
          color: #fff;
        }

      }
      a{
        height: 34px;
        display: inline-block;
        background-color: #d9efe2;
        border-radius: 3px;
        line-height: 34px;
        padding: 0 20px;
        color: #01953f;
      }
    }
  }
  .companyInfo-tcon{
    margin: 10px 20px;
    .cpsupplyList{
      font-size: 0;
      margin: 0 -26px 0 0;
      li{
        width: 196px;
        height: 239px;
        background-color: #ffffff;
        border: solid 1px #e0e0e0;
        font-size: 14px;
        margin-right: 20px;
        margin-top: 15px;
        display: inline-block;
        img{
          display:block;
          width: 196px;
          height: 150px;
        }
        .cpsupplyList-txt{
         padding: 5px 10px;
          display: block;
        }
        span{
          display: block; padding: 0 10px; font-size: 14px; color: #fd641f;
          b{
            font-weight: normal; font-size: 18px;
          }
        }
      }
    }
    .cppureList{
        li{
          height:85px; position: relative; margin-top: 15px;
          padding-right: 150px;
          border-bottom: 1px dashed #e8e8e8;
          h3{
            font-size: 16px; font-weight: bold;
          }
          p{
            line-height: 25px;
            margin-top: 5px;
            color: #787878; display: block;
            height:26px; overflow: hidden; text-overflow: ellipsis;
          }
          .cppureList-list-tips{
            margin-top: 10px;
            .cppureList-list-zone{
              display: inline-block; background-image: url('/statics/images/icon/icon_ps.png');
              background-repeat: no-repeat;
              background-position: 0 center;
              padding-left: 18px;
              margin-right: 30px;
            }
            .cppureList-list-ptime{
              display: inline-block;
              color: #787878;
            }
          }
          .cppureList-list-btn{
            position: absolute;
            right: 20px;
            top:30px;
            line-height: 24px;
            border:1px solid #e8e8e8;
            padding:2px 10px;
          }

        }

    }

  }
}
.companyAside{
   width: 284px;
  .companyLink{
    padding-bottom: 15px;
    dl{
     margin: 10px  20px 0  20px;
      &.companyLink-item--line{
        border-bottom: 1px dashed #e8e8e8; padding-bottom: 10px;
      }
      dt{
        font-size: 14px;
        height: 20px;
        margin-top: 5px;

        i{
          display: inline-block;
           background-position: 50% 50%;
           background-repeat: no-repeat;
          height: 24px;
          width: 24px;
          vertical-align: -6px;
          margin-right: 5px;
          &.icon-cpaddr{
            background-image:url(/statics/images/icon/icon_home.png);
          }
          &.icon-pos{
            background-image:url(/statics/images/icon/icon_ps.png);
          }
          &.icon-linkman{
            background-image:url(/statics/images/icon/icon_userhead.png);
          }
          &.icon-linktel{
            background-image:url(/statics/images/icon/icon_telphone.png);
          }
          &.icon_boxs{
            background-image:url(/statics/images/icon/icon_boxs.png);
          }
        }
      }
      dd{
        color: #878787; margin-top: 10px; padding-left: 28px; line-height: 24px;
      }
    }

  }  
  .imgShow{
    width: 284px;
    height: 632px;
    line-height: 37px;
    color:#333333;
    .imgShow-list{
      margin-top: 18px;    
      .img{
        width: 241px;
        height: 147px;
      }
    }
  }
  .code{
    width: 284px;
    height: 284px;
    .code-img{
      width: 160px;
      height: 160px;
      margin: 28px 20px 0 65px;
    //  background: url(/statics/images/temp/code.png) no-repeat;
    }
    p{
      text-align:center;
      line-height: 37px;
      color:#333333;
    }
  }
  .map{
    width: 284px;
    height: 259px;
    margin-bottom: 32px;
    .map-img{
      background-color: #f6f6f6;
      width: 241px;
	    height: 182px;
    }
  }
}
/**单页-关于我们**/
.pageLeft{
  width: 204px;
  h3{
    display: block;
    height: 53px;background-color: #01953f; font-weight: bold; font-size: 16px; letter-spacing: 1px; line-height: 53px; color: #fff; text-align: center;
    i{
       width: 25px; height: 25px;   display: inline-block;
      &.icon-notice{
         margin-right: 5px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image:url(/statics/images/icon/icon-notice.png);
        vertical-align: -6px;
      }
    }
  }
  ul{

    li{
       height: 54px;  border-bottom: 1px solid #e8e8e8;  border-left: 1px solid #e8e8e8; border-right: 1px solid #e8e8e8;
       &.cur{
        a{
          background-color: #e5f4eb;
        }
      }
      a{
        display: block; height: 54px; background: #fff; line-height: 54px;text-align: center;
        &:hover{
          background-color: #e5f4eb;
          i{
            background-image:url(/statics/images/icon/icon_arrow_h.png);
          }
        }
        i{
          width: 25px; height: 25px;   display: inline-block;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-image:url(/statics/images/icon/icon_arrow.png);
          vertical-align: -6px;
        }
      }
    }
  }

}
.pageContent{
   width: 972px;
   min-height: 560px;
  .pageContent-info{ 
  }
  .pageContent-contactus{
    ul{
      margin: 0 20px;
      font-size: 0;
      li{ width: 50%; height: 60px; display: inline-block; font-size: 14px; vertical-align: top;margin-top: 15px;
        i{
           width: 30px; height: 30px; display: inline-block;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          vertical-align: -10px;
          &.icon_rmsg{
            background-image:url(/statics/images/icon/icon_rmsg.png);

          }
          &.icon_rtel{
            background-image:url(/statics/images/icon/icon_rtel.png);
          }
          &.icon_ruser{
            background-image:url(/statics/images/icon/icon_ruser.png);
          }
          &.icon_rpos{
            background-image:url(/statics/images/icon/icon_rpos.png);
          }
        }
      }
    }
  }
  h4{
    font-weight: bold; font-size: 14px;
    margin: 0 20px;
  }
  .pageContent-map{
    margin: 0 20px;
    height:340px;
    width: 929px;

    border: solid 1px #e8e8e8;

  }
}
/**
新闻栏目
 */
.newsSwiper{
  width: 636px;
  height: 382px;

  position: relative;
  z-index: 1;
  &.swiper-container{
    margin:0 auto;
    position:relative;
    overflow:hidden;
    direction:ltr;
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
    /* Fix of Webkit flickering */
    z-index:1;
    .swiper-wrapper{
      position:relative;
      width:100%;
      -webkit-transition-property:-webkit-transform, left, top;
      -webkit-transition-duration:0s;
      -webkit-transform:translate3d(0px,0,0);
      -webkit-transition-timing-function:ease;

      -moz-transition-property:-moz-transform, left, top;
      -moz-transition-duration:0s;
      -moz-transform:translate3d(0px,0,0);
      -moz-transition-timing-function:ease;

      -o-transition-property:-o-transform, left, top;
      -o-transition-duration:0s;
      -o-transform:translate3d(0px,0,0);
      -o-transition-timing-function:ease;
      -o-transform:translate(0px,0px);

      -ms-transition-property:-ms-transform, left, top;
      -ms-transition-duration:0s;
      -ms-transform:translate3d(0px,0,0);
      -ms-transition-timing-function:ease;

      transition-property:transform, left, top;
      transition-duration:0s;
      transform:translate3d(0px,0,0);
      transition-timing-function:ease;

      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      a{
        width: 100%;
        height: 100%;
        display: block;
        float: left;
        .newsSwiper-linkcon{
          display: inline-block;
          width: 100%; height: 100%;
          position: relative;
          .newsSwiper-linkcon-txt{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 50px;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#FF000000',GradientType=0 ); /* IE6-9 */
            background:none; /* 一些不支持背景渐变的浏览器 */
            background:-moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
            background:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 0, 0.0)), to(rgba(0, 0, 0, 0.5)));

            span{
              color: #fff; font-size: 16px; line-height: 48px; letter-spacing: 2px;
              padding: 20px 0 20px 20px; white-space: nowrap;
            }
          }

        }

      }
    }
    .newsSwiper-page{
      position: absolute;
      right:10px;
      width:auto ;
      margin: 0 auto;
      height:30px;
      bottom:10px;
      text-align: center;
      span{
        width: 6px; height: 6px; display: inline-block; *display:inline;*zoom:1; background:#fff; border-radius: 50%; opacity: 0.5;
        margin: 0 5px;
        &.swiper-active-switch{
          opacity: 1;
        }
      }
    }

  }
}
.newsTop{
   width: 564px;
  height: 382px;
  ul{
    margin: 0 20px;
    li{
      height: 120px; border-bottom: solid 1px #e8e8e8;
      &:last-child{
        border-bottom: none;
      }
      h3{
        a{
           display: block; white-space: nowrap;
          margin-top: 20px;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 2px; font-weight: bold;
        }
      }
      p{
        height: 39px;display: block; margin-top: 20px;
        font-size: 14px;

        line-height: 20px;
        letter-spacing: 1px;
        color: #787878;
      }
    }
  }

}
.newsBlock{
  font-size: 0;
  margin-right:-24px;
  .newsBlock-item{
     width: 384px; font-size: 14px; display: inline-block;margin-right: 24px; vertical-align: top;
    height: 420px; margin-top: 20px;
    .newsBlock-item-posi{
      margin: 0 20px;
      position: relative;
      padding-left: 141px;
      padding-top: 10px;
      img{
        display: block; width: 119px; height: 90px;
        position: absolute;
        left: 0;
        top: 10px;
      }
      h3{
        a{
          height: 39px;
          font-size: 14px;line-height: 20px;
          letter-spacing: 1px;
          font-weight: bold;
          display: block;
           overflow: hidden;
        }
      }
      p{
        margin-top: 10px;
        height: 39px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        display: block;
        overflow: hidden;color: #787878;
      }
    }
    ul{
      margin: 0 20px; padding-top: 10px;
      li{
        background-image: url(/statics/images/bg/bg_dot.png);
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 15px;
        overflow: hidden;
        line-height: 30px;
        white-space: nowrap;
      }
    }
  }
}
/**供货求购**/
.suppurefilter{

   .suppurefilter-tab{
     position: relative;

     background-image: url("/statics/images/bg/bg_hline.jpg");
     background-repeat: repeat-x;
     background-position: 0 bottom;
     height: 40px; font-size: 0;
     li{
       height: 40px; font-size: 16px; display: inline-block;letter-spacing: 2px; color: #787878; margin-right: 40px;
       &.cur{
         border-bottom: 4px solid #01953f; height: 37px;
         a{
           color: #333;
           font-weight: bold;
         }
       }

     }
     .moreLink{
       position: absolute;
       right: 10px;
       display: inline-block;
     }
   }
}
/**求购列表**/
.suppurefilter-filter{
   margin: 0 20px;
  border: solid 1px #e8e8e8;
  font-size: 0;
  position: relative;
  height: auto; overflow: hidden;

  dt{
    font-size: 14px; display: inline-block; vertical-align: top; height: 200px; line-height: 49px; width: 80px; background-color: #f6f6f6; text-align: center;
  }
  dd{
    font-size: 0; display: inline-block;vertical-align: top; padding: 0 0 0 0; width: 1020px;
    &>ul{
      font-size: 0; font-size: 14px;
       &>li{
        display: inline-block; font-size: 14px; height: 18px; margin: 10px 10px 0 10px; padding:5px 10px ;
         &.cur{
           border:1px solid #01953f; letter-spacing: 1px;
           color: #01953f;
         }
      }
    }

  }
  .suppurefilter-filter-itembtn{
     display: inline-block; height: 28px; line-height: 28px; width: 63px; font-size: 14px; 	border: solid 1px #e8e8e8; text-align: center;
    position: absolute;
    right: 10px;top: 10px;

  }
  //关闭
  &.suppurefilter-filter--close{
    height: 49px;

  }
  &.suppurefilter-filter--notop{
    border-top: none;
  }
  &.suppurefilter-filter--search{
    height: 49px;
    .suppurefilter-filter-search{
      font-size: 14px; margin:8px 0 0 15px;
      input[type=text]{
        display: inline-block;  width: 200px; height: 35px;
      }
      .layui-btn{
        background-color: #01953f;
      }
    }
  }

}
.pureList{
  .pureList-list{
    margin: 0 20px;
    ul{
      li{
        height:146px; position: relative; margin-top: 15px;
        padding-right: 150px;
        border-bottom: 1px dashed #e8e8e8;
        h3{
          font-size: 16px; font-weight: bold;
        }
        p{
          line-height: 25px;
          display: block;
          height: 43px;
          margin-top: 20px;
          color: #787878; display: block;
          overflow: hidden; text-overflow: ellipsis;
        }
        .pureList-list-tips{
          margin-top: 30px;
          .pureList-list-zone{
            display: inline-block; background-image: url('/statics/images/icon/icon_ps.png');
            background-repeat: no-repeat;
            background-position: 0 center;
            padding-left: 18px;
            margin-right: 30px;
          }
          .pureList-list-ptime{
            display: inline-block;
            color: #787878;
          }
        }
        .pureList-list-btn{
          position: absolute;
          right: 20px;
          top:50px;
          line-height: 24px;
          border:1px solid #01953f;
          padding:5px 20px;
          background-color: #01953f;
          color: #fff;
          border-radius: 3px;
          font-size: 16px;
          &.pureList-list-btn--end{

              background: #e8e8e8;
              color: #333;
              cursor: default;
              border:1px solid #e8e8e8


          }
        }

      }
    }
  }
  .pureList-option{
    border-bottom: solid 1px #e8e8e8; padding-bottom: 15px;
    .pureList-option-num{
      line-height: 34px;
      color: #787878;
      span{
        color: #01953f;
      }
    }
    .pureList-option-btn{
      a{
        display: inline-block;
        width: 124px;
        height: 34px;
        background-color: #fe9941;
        border-radius: 3px;
        text-align: center;
        line-height: 34px;
        color: #fff;
      }

    }
  }
}

.supList{
  .supList-option{
    border-bottom: solid 1px #e8e8e8; padding-bottom: 15px;
    .supList-option-num{
      line-height: 34px;
      color: #787878;
      span{
        color: #01953f;
      }
    }
    .supList-option-btn{
      a{
        display: inline-block;
        width: 124px;
        height: 34px;
        background-color: #fe9941;
        border-radius: 3px;
        text-align: center;
        line-height: 34px;
        color: #fff;
      }

    }
  }
}
/**供求详情**/
.showspLeft{
  width: 892px;


}
.showspRight{
  width: 284px;
  //供应企业信息
  .showspcp{
    .showspcp-avator{
      width: 242px;
      height: 92px;
      background-color: #ffffff;
      border: solid 1px #e8e8e8;
      display: block;
      margin: 10px auto;
    }
  }
  //求购相关
  .showspPure{
    ul{
      margin: 0 20px;
      padding-bottom: 20px;
      li{
        margin-top: 15px; border-bottom: 1px dashed #dadada; padding-bottom: 10px;
        a{
          font-size: 14px; height: 42px; display: block; line-height: 20px;
        }
        span{
          display: block; font-size: 14px; color: #787878; margin-top: 10px;
        }
        em{
          display: block; font-style: normal;
          margin-top: 10px;
          background-image: url('/statics/images/icon/icon_ps.png');
          background-repeat: no-repeat;
          background-position: 0 center;
          padding-left: 18px;
        }
      }
    }
  }
}
//求购信息
.showpurInfo{
  .showpurInfo-con{
    margin: 0 20px;
    dl{
      margin: 0 20px;
      border: solid 1px #e8e8e8;
      font-size: 0;
      &.showpurInfo-con-item--notop{
        border-top: none;
      }
      dt{
        font-size: 14px; display: inline-block; vertical-align: top; height: 100%; line-height: 49px; width: 80px; background-color: #f6f6f6; text-align: center;
      }
      dd{
        font-size: 14px; display: inline-block; width:710px;padding: 10px;
      }
    }
  }

}
//求购弹出层
.layquto-select{
  .layui-form-select{
    display: inline-block; width: 150px;
  }
  .layquto-select-uplist{
    img{
      display: inline-block; width: 120px; height: 120px;
    }
  }
}
/**供货详情**/
.showspInfo{
   position: relative; height: 440px;
  .showspInfo-swiper{
    width: 349px;
    height: 420px;
   .showspInfo-swiper-img{
     width: 349px;height: 349px;margin-bottom: 10px;position: relative;border:1px solid #E8E8E8;cursor:move;
     img{
       width: 349px;
       height: 349px;
     }
     .showspInfo-swiper-mask{
       width: 215px;height: 215px;background: #000;filter: Alpha(opacity=50);opacity:0.5;position: absolute;top: 0;left: 0;display: none;
     }
   }
    .showspInfo-swiper-container{
      width: 102%;height: 54px;position: relative;
      img{
        width:100%;border:1px solid #e8e8e8;box-sizing: border-box;transition: all 0.5s
      }
      .middle_box{margin-left: 23px;width:315px;overflow: hidden;height: 70px;}
      .middle{overflow: hidden;transition: all 0.5s;height: 70px;}
      .middle>li{width: 52px;height: 52px;float: left;cursor:pointer;padding:0 5px;
        img{
          display: block; height: 50px; width: 50px;
        }
      }
      &>p{position: absolute;top:50%;width: 22px;height: 32px;margin-top: -16px;}
      .prev{left: 0; top:30px;transition: all 0.5s; font-size: 24px; color: #333333; text-align: left;
       &.prevnone{cursor: not-allowed;color: #dbdbdb;}
      }
      .next{right: 0; top:30px;font-size: 24px; color: #333333;transition: all 0.5s;
         &.nextnone{cursor: not-allowed;color: #dbdbdb;}
      }
    }


  }
  .bg_right{
    position: absolute; top:0; left: 360px;
    .bigshow{width: 390px;height: 390px;overflow: hidden;margin-left: 10px;display: none;border:1px solid #E8E8E8;}
    .bigshow img{width: 860px;height: 860px;margin-right: 10px;}
  }
  .showspInfo-info{
    width: 487px;
    h1{
      height: 48px;font-size: 16px;line-height: 30px; letter-spacing: 1px; font-weight: bold;
    }
    .showspInfo-info-area{
      font-size: 14px;letter-spacing: 1px;
      color: #787878;
    }
    .showspInfo-info-pitime{
      font-size: 14px;letter-spacing: 1px;
      color: #787878;
    }
    .showspInfo-info-price{
       padding: 15px;background-color: #f6f6f6;
      h3{
        letter-spacing: 1px;
        color: #787878; font-size: 14px;
        b{
            font-size: 16px;
        }
      }
      h4{
        letter-spacing: 1px;
        color: #787878;
      }
    }
  }

}
.showspIntro{
  .showspIntro—con{
     padding-bottom: 20px;

  }
}
.showspPro{
  ul{
    margin: 0 20px; padding-bottom: 20px;
    .showspPro-item{
      margin-top: 15px;
      img{
        display:block;width: 242px;
        height: 242px;border: solid 1px #e8e8e8;
      }
      .showspPro-item-txt{
        font-size: 14px; line-height: 34px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
      }
      em{
        letter-spacing: 1px;
        color: #e73332;
        font-style: normal;
        text-align: center;
        display: block; margin-top: 10px;
      }
    }
  }

}
/**
行情
 */
.showMarket{
  .showMarket-canvas{
     height:450px;
  }
}


.layui-input-block{
  margin-left: 0;
}
/*批发市场*/
.bg--marketBanner{
  height: 335px;
  background-color: #ccc;
  background: url(/statics/images/images/bg_market.png)no-repeat center center;
}  
.title{
  font-size: 26px;
  letter-spacing: 1px;
  color:#303030; 
  line-height: 30px;
  em{
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    border-bottom: #d5d5d5 1px solid;
  }
}
.intro{
  .intro-area{
    width: 351px;
    display: inline-block;
    font-style: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    &.border-r{
      border-right: 1px solid #d5d5d5;
    }
    .info{
      font-size: 22px;
      color: #01953f;
    }
  }
  .content{
    text-indent: 2em;
    line-height: 30px;
    letter-spacing: 1px;
    color: #303030;
    font-size: 14px;
    text-align: left;
  }
}
.images{
  height: 210px;
  li{
    float: left;
    margin-left: 63px;
    width: 315px;
    height: 210px;
    background-color: #eeeeee; 
    background: no-repeat center center;
    background-size: contain;
    &.img-one{
      background-image: url(/statics/images/images/img-one.png);
    }
    &.img-two{
      background-image: url(/statics/images/images/img-two.png);
    }
    &.img-three{
      background-image: url(/statics/images/images/img-three.png);
    }
  }
}
.advance{
  position: relative;
   width: 1025px;
   left: 20px;
   li{
    border: solid 1px #d5d5d5;
    i{
      display: block;
      width: 76px;
      height: 76px;
      position: relative;
      right:40px;
      background: no-repeat center center;
      &.product{
        top:48px;
        background-image: url(/statics/images/images/product.png);
      }
      &.area{
        top:68px;
        background-image: url(/statics/images/images/area.png);
      }
      &.plan{
        top:37px;
        background-image: url(/statics/images/images/plan.png);
      }
      &.bus{
        top:61px;
        background-image: url(/statics/images/images/bus.png);
      }
      &.admin{
        top:50px;
        background-image: url(/statics/images/images/admin.png);
      }
      &.data{
        top:48px;
        background-image: url(/statics/images/images/data.png);
      }
      &.policy{
        top:80px;
        background-image: url(/statics/images/images/policy.png);
      }
      &.study{
        top:182px;
        background-image: url(/statics/images/images/study.png);
      }
    }
    .advance-info{
      width: 916px;
      position: relative;
      bottom:40px;
      line-height: 30px;
	    letter-spacing: 1px;
      h4{
        font-size: 16px;
        span{
          color: #01953f;
        }
      }
      .content{
        font-size: 14px;
        text-indent: 2em;
      }
    }
   }
}