* {
  margin: 0;
  padding: 0;
  border: 0;
}
body {
  overflow-x: hidden;
  font-family: "Microsoft YaHei", "MicrosoftJhengHei";
  background-color: #f6f6f6;
  //overflow:auto;
  //overflow-x:scroll;//横向滚动条
}
.l {
  float: left;
}
.r {
  float: right;
}
.cl {
  clear: both;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Microsoft YaHei", "MicrosoftJhengHei";
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
p,
li,
dt,
dd,
img {
  margin: 0;
  padding: 0;
  border: 0;
}
ol,
ul {
  list-style: none;
}
a {
  cursor: pointer;
}
a:link,
a:visited {
  text-decoration: none;
  -webkit-transition: "all 0.3s ease-out";
  -moz-transition: "all 0.3s ease-out";
  -o-transition: "all 0.3s ease-out";
  transition: "all 0.3s ease-out";
}
a:hover {
  color: #01953f;
}
.fix {
  *zoom: 1;
}
.fix:after,
.fix:before {
  display: table;
  content: "";
  clear: both;
}
/**循环**/
@for $i from 0 through 100 {
  /**大于10 每隔5像素增加直到50**/
  @if (($i<=10) or ($i>10 and $i%5==0)) {
    .pt#{$i} {
      padding-top: #{$i}px !important;
    }
    .pb#{$i} {
      padding-bottom: #{$i}px !important;
    }
    .pl#{$i} {
      padding-left: #{$i}px !important;
    }
    .pr#{$i} {
      padding-right: #{$i}px !important;
    }
    .mt#{$i} {
      margin-top: #{$i}px !important;
    }
    .mb#{$i} {
      margin-bottom: #{$i}px !important;
    }
    .ml#{$i} {
      margin-left: #{$i}px !important;
    }
    .mr#{$i} {
      margin-right: #{$i}px !important;
    }
  }
}
.mc {
  margin: 0 auto;
}
/* 文字对齐 */
.tl {
  text-align: left !important;
}
.tr {
  text-align: right !important;
}
.tc {
  text-align: center !important;
}
/*字体颜色*/
.f_bluelight {
  color: #1db0fc !important;
}
.f_bluedeep {
  color: #1c53a5 !important;
}
.f_reddeep,
.warning {
  color: #df2023 !important;
}
.f_greenlight {
  color: #01953f !important;
}
.f_yellowdeep {
  color: #d1a677 !important;
}
.f_g3 {
  color: #333 !important;
}
.f_g6 {
  color: #666 !important;
}
.f_g9 {
  color: #999 !important;
}
.f_g78 {
  color: #787878 !important;
}
.bg-white {
  background-color: #fff;
}
/**通用**/
.container {
  width: 1200px;
  margin: 0 auto;
  h2 {
    background-image: url("/statics/images/bg/bg_hline.jpg");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    display: block;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
    b {
      border-bottom: 4px solid #01953f;
      height: 31px;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 2px;
      &.tab{
        border-bottom: none;
      }
      &.tab--on{
        border-bottom: 4px solid #01953f;
      }
    }
  }
}
// 更多按钮
.moreLink {
  background-image: url("/statics/images/icon/icon_more.png");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 18px;
  color: #787878;
  font-size: 14px;
  &:hover {
    color: #333;
  }
}
//面包屑
.crumb {
  .crumb-iconps {
    display: inline-block;
    width: 12px;
    height: 20px;
    vertical-align: -5px;
    margin-right: 5px;
    background-image: url("/statics/images/icon/icon_grayps.png");
    background-position: center center;
    background-repeat: no-repeat;
  }
}
// 页头
.header {
  width: 100%;
  height: 162px;
  background-color: #f8f8f8;
  &.header--user {
    height: auto;
  }
  .header-bar {
    height: 26px;
    background-color: #e8e8e8;
    .header-bar-txt {
      font-size: 12px;
      line-height: 24px;
    }
    .header-bar-option {
      font-size: 12px;
      .header-bar-info {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        position: relative;
        h3 {
          font-size: 12px;
        }
        .header-bar-header {
          background-image: url("/statics/images/icon/icon_smheader.png");
          background-repeat: no-repeat;
          background-position: 0 3px;
          display: inline-block;
          width: 20px;
          height: 20px;
          vertical-align: -3px;
        }
        .header-bar-uitems {
          display: none;
          position: absolute;
          top: 25px;
          z-index: 100;
          background-color: #fff;
          padding: 0 20px;
           width: 80%;
           text-align: center;
          &.header-bar-uitems--on {
            display: block;
          }
          ul {
            padding: 10px 0;
            li {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .header-wrap {
    width: 100%;
    height: 93px;
    background-image: url(/statics/images/bg/bg_indextp.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    &.header-wrap--user {
      background-image: url(/statics/images/bg/bg_user.jpg);
    }
    a.logo {
      display: block;
      margin-right: 180px;
      width: 340px;
      height: 88px;
      background: url(/statics/images/logo.png) 0 center no-repeat;
    }
    .header-search {
      position: relative;
      width: 339px;
      height: 36px;
      margin-top: 30px;
      margin-right: 39px;
      span {
        cursor: pointer;
        display: block;
        width: 60px;
        height: 36px;
      }
      .searchSec{
        width: 60px;
        height: 36px;
        left: 2px;
        text-indent: 10px;
        line-height: 35px;
        border: 1px solid rgba(210,210,210,.8);
        background:rgba(238, 233, 233, 0.8) url(/statics/images/icon/arrow.png) 45px center no-repeat;
        &.seek--select{
          position: relative;
          left: -10px;
        }
        .select{     
          width: 59px;
          display: none;
          z-index: 1050;
          position: relative;
          li{
            width: 100%;
            height: 30px;
            line-height: 30px;
            border:1px solid rgba(218, 215, 215, 0.8);
            background-color: rgba(238, 233, 233, 1);
            cursor: pointer;
            &:hover{
              background-color:#71d49a;
              color: #fff;
            }
          }
        }
        &:hover{
          .select{
            display: block;
          }
        }
      }
      // .searchSec{
      //   left: 2px;
      //   position: relative;
      //   text-indent: 10px;
      //   line-height: 35px;
      //   &.seek--select{left: -32px}
      //   select{
      //     width: 75px;
      //     height: 38px;  
      //     text-indent: 5px; 
      //     border: 1px solid rgba(210,210,210,.8); 
      //     background-color: rgba(238,233,233,.8);
      //     option{
      //       width: 75px;
      //       height: 38px;
      //       text-indent: 10px;
      //     }
      //   }  
      // }
      .header-search-input {
        width: 283px;
        height: 36px;
        background-color: #f6f6f6;
        border-radius: 5px;
        border: solid 1px rgba(210, 210, 210, 0.8);
        text-indent: 10px;
        position: absolute;
        z-index: 1;
        &.seek{left: 51px;}
      }
      
      a{
        background-image: url(/statics/images/icon/icon_tpsearch.png);
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        background-position: 50% 50%;
        display: inline-block;
        position: absolute;
        z-index: 10;
        width: 37px;
        height: 35px;
        right: -7px;
        top: 1px;
        border-radius: 0 5px 5px 0;
        &.seek--a{
          right: 6px;
        }
      }
    }
  }
  .header-nav {
    height: 45px;
    background-color: #01953f;
    width: 100%;
    // 导航
    .nav {
      height: 45px;
      .nav-title {
        font-size: 0;
        text-align: left;
        background-image: url(/statics/images/bg/bg_navline.jpg);
        background-repeat: no-repeat;
        background-position: left 0;
        .nav-title-li {
          background-image: url(/statics/images/bg/bg_navline.jpg);
          background-repeat: no-repeat;
          background-position: right 0;
          line-height: 45px;
          position: relative;
          display: inline-block;
          font-size: 16px;
          padding: 0 30px;
          a {
            span {
              display: block;
              height: 45px;
              font-size: 16px;
              letter-spacing: 0px;
              color: #fff;
              &:hover {
              }
              &.nav-title-li--cur {
                color: #fff;
                background-color: #06c255;
              }
            }
            img {
              margin-left: 3px;
            }
          }
          // 下拉菜单
          .triangle {
            display: none;
            position: absolute;
            z-index: 11;
            margin-top: 6px;
            margin-left: 27px;
            width: 15px;
            height: 10px;
            background: url(/statics/images/border/triangle.png) top center
              no-repeat;
          }
          .nav-title-menu {
            display: none;
            width: 155px;
            position: absolute;
            z-index: 9;
            left: -41px;
            margin-top: 15px;
            background-color: #f4f4f4;
            border: 1px solid #e7e7e7;
            box-shadow: 0px 0px 3px #e7e7e7;
            margin-left: 25px;
            li {
              width: 154px;
              height: 40px;
              margin: 0;
              text-align: center;
              border-bottom: 1px solid #e7e7e7;
              xxx a {
                width: 155px;
                height: 40px;
                text-indent: 35px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 40px;
                letter-spacing: 0px;
                color: #303030;
                &:hover {
                  color: #3d8ce5;
                }
              }
            }
          }
          //装饰器
          &.nav-title-li__hover {
            color: #fff;
            background-color: #06c255;

            .nav-title-menu {
              display: block;
              -webkit-animation-duration: 0.5s;
              animation-duration: 0.5s;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
              -webkit-animation-name: zoomIn;
              animation-name: zoomIn;
            }
          }
          //选中        
          &.nav-btn-current {
            color: #fff;
            background-color: #06c255;
          }
        }
      }
    }
  }
}
// 返回顶部
.back {
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 999;
  cursor: pointer;
  a {
    margin: 1px 0;
    width: 45px;
    height: 45px;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 1px;
    color: #ffffff;
    text-align: center;
    &.back-up {
      background: url(/statics/images/back/home.png) #b4b4b4 center center
        no-repeat;
    }
    &.back-wechat {
      background: #b4b4b4 center center no-repeat;
    }
    &.back-tel {
      background: url(/statics/images/back/headset.png) #b4b4b4 center center
        no-repeat;
    }
    &.back-note {
      background: url(/statics/images/back/txt.png) #b4b4b4 center center
        no-repeat;
    }
    &.back-top {
      background: url(/statics/images/back/top.png) #b4b4b4 center center
        no-repeat;
    }
    &:hover {
      background-color: #65bb4e;
      .back-wechat {
        display: none;
      }
    }
  }
  .wechat-pic {
    display: none;
    position: absolute;
    right: 45px;
    top: 14px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px #e7e7e7;
    img {
      width: 180px;
    }
  }
}
.back .back-wechat:hover .wechat-pic {
  display: block;
}

// 页脚
.footer {
  width: 100%;
  height: 82px;
  background-color: #fff;
  color: #333333;
  clear: both;
  border-top: 1px solid #e8e8e8;
  .footer-nav {
    text-align: center;
    ul {
      padding: 20px 0 0 0;
      li {
        border-right: 1px solid #d4d4d4;
        height: 18px;
        display: inline-block;
        a {
          padding: 0 10px;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .footer-remark {
    width: 100%;
    height: 46px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 46px;
    letter-spacing: 1px;
  }
}

//翻页
.pager {
  margin: 30px 0;
  text-align: center;
  .pager-ul {
    display: flex;
    justify-content: center;
    a {
      margin-right: 5px;
      display: block;
      width: 36px;
      height: 36px;
      background-color: #ffffff;
      border: solid 1px #01953f;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #666;
      text-align: center;
      border-radius: 3px;
      &.pager-ul-turn {
        width: 63px;
        height: 36px;
      }
      &.pager--cur {
        background-color: #01953f;
        color: #fff;
        display: block;
      }
      &.pager-ul--cur {
        border: solid 1px #01953f;
        background-color: #01953f;
        color: #fff;
      }
      &:hover {
        border: solid 1px #01953f;
        background-color: #01953f;
        color: #fff;
      }
    }
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #666666;
    }
    input {
      margin: 0 5px;
      width: 36px;
      height: 36px;
      background-color: #ffffff;
      border: solid 1px #cccccc;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #666;
      text-align: center;
    }
    button {
      margin-left: 5px;
      width: 74px;
      height: 36px;
      border-radius: 3px;

      background-color: #ffffff;
      border: solid 1px #cccccc;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #666;
      text-align: center;
      &:hover {
        border: solid 1px #01953f;
        background-color: #01953f;
        color: #fff;
      }
    }
  }
}
.page--first {
}
.page--two {
}

/**layui**/
.layui-btn {
  background-color: #01953f;
}

body .layui-layer-page .layui-layer-content {
  overflow: visible;
}
.layui-form-select dl {
  max-height: 200px;
}

body .layui-layer-btn .layui-layer-btn0 {
  background-color: #01953f;
  border: 1px solid #01953f;
}
.layui-input:hover,
.layui-textarea:hover {
  border-color: #01953f !important;
}
body .layui-laypage .layui-laypage-curr .layui-laypage-em {
  background-color: #01953f;
}
.layui-btn-primary {
  background-color: #fff;
}
.layui-tree-entry .layui-tree-main .treecur {
  background-color: #01953f;
  color: white;
}
.layui-input-block .goodsname {
  width: 80%;
}
.layui-upload .layui-input-block .layui-btn {
  margin: 0 0 10px 0;
}
.layui-upload .layui-input-block .layui-elem-quote {
  width: 76%;
  margin-left: 109px;
}
.agreement {
  width: 100%;
  height: auto;
  h3 {
    font-weight: 700;
    margin: 25px 0 25px 0;
    text-align: center;
    font-family: "Microsoft YaHei", MicrosoftJhengHei;
  }
  p {
    padding: 30px;
  }
}
